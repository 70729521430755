import React from "react";
import "./Gallery.css";
import { useState } from "react";

const Gallery = () => {
  const slides = [
    { url: "/slide1.png", title: "slide1" },
    { url: "/slide2.png", title: "slide2" },
    { url: "/slide3.png", title: "slide3" },
    { url: "/slide4.png", title: "slide4" },
    { url: "/slide5.png", title: "slide5" },
    { url: "/slide6.png", title: "slide6" },
    { url: "/slide7.png", title: "slide7" },
    { url: "/slide8.png", title: "slide8" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideStyles = {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundImage: `url(${slides[currentIndex].url})`,
  };
  const sliderStyles = {
    height: "100%",
    postion: "relative",
  };

  const dotsContainerStyles = {
    display: "flex",
    justifyContent: "center",
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  return (
    <div className="Gallery">
      <h1>Gallery</h1>
      <div className="Gallery-big" style={{ width: "60rem", height: "35rem" }}>
        <div style={sliderStyles}>
          <div
            style={{
              postion: "absolute",
              top: "50%",
              transform: "translate(-14%, 570%)",
              fontSize: "45px",
              zIndex: 1,
              cursor: "pointer",
            }}
            onClick={goToPrevious}
          >
            〈
          </div>
          <div
            style={{
              postion: "absolute",
              top: "50%",
              transform: "translate(0%, 470%)",
              fontSize: "45px",
              zIndex: 1,
              cursor: "pointer",
              marginLeft: "65rem",
            }}
            onClick={goToNext}
          >
            〉
          </div>
          <div style={slideStyles}></div>
        </div>
      </div>

      <div
        className="Gallery-small"
        style={{ width: "26rem", height: "17rem" }}
      >
        <div style={sliderStyles}>
          <div
            style={{
              postion: "absolute",
              top: "50%",
              transform: "translate(-14%, 330%)",
              fontSize: "45px",
              zIndex: 1,
              cursor: "pointer",
            }}
            onClick={goToPrevious}
          >
            〈
          </div>
          <div
            style={{
              postion: "absolute",
              top: "50%",
              transform: "translate(0%, 240%)",
              fontSize: "45px",
              zIndex: 1,
              cursor: "pointer",
              marginLeft: "27rem",
            }}
            onClick={goToNext}
          >
            〉
          </div>
          <div style={slideStyles}></div>
        </div>
      </div>
      <div
        className="Gallery-medium"
        style={{ width: "730px", height: "500px" }}
      >
        <div style={sliderStyles}>
          <div
            style={{
              postion: "absolute",
              top: "50%",
              transform: "translate(-14%, 510%)",
              fontSize: "45px",
              zIndex: 1,
              cursor: "pointer",
            }}
            onClick={goToPrevious}
          >
            〈
          </div>
          <div
            style={{
              postion: "absolute",
              top: "50%",
              transform: "translate(0%, 415%)",
              fontSize: "45px",
              zIndex: 1,
              cursor: "pointer",
              marginLeft: "49rem",
            }}
            onClick={goToNext}
          >
            〉
          </div>
          <div style={slideStyles}></div>
        </div>
      </div>
      <div style={dotsContainerStyles}>
        {slides.map((slide, slideIndex) => (
          <div
            className="dotStyles"
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            ●
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
