import React from "react";
import "./About.css";
const About = () => {
  return (
    <div className="About">
      <div className="About-content">
        <h1>About Our Therapist</h1>
        <div className="About-desc">
          <img src="/850759718be3ccf6d6b750f3ffe00110.png" alt="Error"></img>
          <div className="About-desc_container">
            <h2>Xuehuan (Shirley) Tan </h2>
            <p>
              She graduated from Ke Kino Massage Academy in Nov. 2023 and became
              a licensed massage therapist in Dec. 2023.
            </p>
            <p>
              She had previous education and years of professional work
              experience in Chemistry and Computer Science fields. As she sought
              help for chronic back/shoulder pain, she was drawn to Ke Kino and
              started her healing process. She found this wonderful world of
              massage where people could get their pain managed and enjoy a much
              better quality of living. She is fascinated by the knowledge of
              anatomy/kinesiology, and how much it helped in her recovery and
              restoration of health. While she is getting stronger, it's her
              passion to help others in their healing journey.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
