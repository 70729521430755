import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="Contact">
      <h1>Contact Information</h1>

      <div className="Contact-content">
        <div className="Contact-desc-container">
          <div className="Contact-desc">
            <h2>Email</h2>
            <p>shirleylmt9596@gmail.com</p>
          </div>
          <div className="Contact-desc">
            <h2>Phone</h2>
            <p>469-988-9039</p>
          </div>
          <div className="Contact-desc">
            <h2>Locations</h2>
            <div>
              <div className="Contact-address">
                <h3>Main Office</h3>
                <p>5509 Pleasant Valley Drive</p>
                <p>#50</p>
                <p>Plano TX, 75023</p>
                <p>United States</p>
              </div>
              <div className="Contact-address">
                <h3>ACM Health Solutions</h3>
                <p>3900 Pebblecreek Ct</p>
                <p>Suite 101</p>
                <p>Plano TX, 75023</p>
                <p>United States</p>
              </div>
            </div>
          </div>
        </div>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3344.003158746139!2d-96.74105212408092!3d33.056383673548204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c18129c5ad2d3%3A0x3cf72b03da837fb5!2s5509%20Pleasant%20Valley%20Dr%20%2350%2C%20Plano%2C%20TX%2075023!5e0!3m2!1sen!2sus!4v1705525456710!5m2!1sen!2sus"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
