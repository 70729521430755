import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="Navbar">
      <div className="Navbar-links">
        <div className="Navbar-logo">
          <Link to="/">
            <img src="/logo_small.png" alt="massage-logo"></img>
          </Link>
        </div>
        <div className="Navbar-links_container">
          <div className="Navbar-title">
            <p className="text-pop-up-top">
              <Link to="/">Shirley Massage Therapy</Link>
            </p>
          </div>

          <p className="text-pop-up-top">
            <Link to="/about">About</Link>
          </p>
          <p className="text-pop-up-top">
            <Link to="/contact">Contact</Link>
          </p>
          <p className="text-pop-up-top">
            <Link to="/services">Services</Link>
          </p>
          <p className="text-pop-up-top">
            <Link to="/incentives">Incentives</Link>
          </p>
          <p className="text-pop-up-top">
            <Link to="/gallery">Gallery</Link>
          </p>
        </div>
      </div>
      <div className="Navbar-social">
        <Link to="https://posts.gle/xwoLu1">
          <img src="/google_logo.png" alt="error"></img>
        </Link>
        <Link to="https://www.facebook.com/profile.php?id=61555562977264">
          <img src="/facebook_logo.png" alt="error"></img>
        </Link>
      </div>
      <div className="Navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#000000"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#000000"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="Navbar-menu_container scale-up-center">
            <p className="text-pop-up-top">
              <Link to="/">Home</Link>
            </p>
            <p className="text-pop-up-top">
              <Link to="/about">About</Link>
            </p>
            <p className="text-pop-up-top">
              <Link to="/contact">Contact</Link>
            </p>
            <p className="text-pop-up-top">
              <Link to="/services">Services</Link>
            </p>
            <p className="text-pop-up-top">
              <Link to="/incentives">Incentives</Link>
            </p>
            <p className="text-pop-up-top">
              <Link to="/gallery">Gallery</Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
